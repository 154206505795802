import React from 'react'

import { FaShippingFast } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { SlEnvolopeLetter } from "react-icons/sl";

const Services = () => {

  const data = [
    {
      id: 1,
      link: '/info',
      icon: 'BiSupport',
      title: 'Dedicated support',
      body: 'Dedicated assistance and problem-solving available around the clock.'
    },
    {
      id: 2,
      link: 'https://docs.google.com/forms/d/10jmt_pVGD2vj4KO7lDJvlcIXDt0Vd00q6pjDR2i_ZZ0/edit?usp=sharing_eip_se_dm&ts=66df073e',
      icon: 'FaPeopleGroup',
      title: 'EB1 & EB2 visa consultation',
      body: 'Expert guidance on visa requirements, eligibility, and application processes.'
    },
    {
      id: 3,
      link: '/info',
      icon: 'FaShippingFast',
      title: 'Fast-track visa application',
      body: 'Streamlined and expedited visa applications for efficient processing.'
    },
    {
      id: 4,
      link: '/info',
      icon: 'SlEnvolopeLetter',
      title: 'Visa appeal',
      body: 'Tailored letters of support or request for evidence (RFE) for visa applications.'
    },

  ]

  const iconMap = {
    BiSupport: <BiSupport />,
    FaPeopleGroup: <FaPeopleGroup />,
    FaShippingFast: <FaShippingFast />,
    SlEnvolopeLetter: <SlEnvolopeLetter />,
  };


  return (
    <section class="section service" aria-labelledby="service-label">
        <div class="container">

          <p class="section-subtitle" id="service-label">What We Do?</p>

          <h2 class="h2 section-title">
            The service we offer is specifically designed to meet your needs.
          </h2>

          <ul class="grid-list ">
            {
              data.map(item => (
                <a key={item.id} href={item.link}>
                    <div class="service-card">
      
                      <div class="card-icon">
                        <ion-icon name="call-outline" aria-hidden="true">{
                            iconMap[item.icon]
                          }</ion-icon>
                      </div>
      
                      <h3 class="h4 card-title">{item.title}</h3>
      
                      <p class="card-text">
                        {item.body}
                      </p>
      
              
                    </div>
                </a>
              ))
            }
          </ul>

        </div>
      </section>
  )
}

export default Services